<template>
 <!--begin::Main-->
		<div class="d-flex flex-column flex-root">
			<!--begin::Login-->
			<div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
				<div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style="background-image: url('/media/bg/bg-3.jpg');">
					<div class="login-form text-center p-7 position-relative overflow-hidden col-lg-4 col-md-6 col-sm-10 cols-10"  >
						<!--begin::Login Header-->
						<div class="d-flex flex-center mb-5">
							<a href="#">
								<img src="/media/logos/whale_logo.png" class="max-h-150px" alt="" />
							</a>
						</div>
						<!--end::Login Header-->
						<!--begin::Login Sign in form-->
						<div class="login-signin">
							<div class="mb-20">
								<h3 style="font-size:1.5rem; font-weight: 900;">Sign In to e-Coupon</h3>
								<div class="text-muted font-weight-bold">Enter your details to login to your account:</div>
							</div>
								<div class="form-group mb-5">
									<input v-model="form.username" class="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Username" name="username" autocomplete="off" />
								</div>
								<div class="form-group mb-5">
									<input v-model="form.password" class="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password" />
								</div>
								<!-- <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
									<div class="checkbox-inline">
										<label class="checkbox m-0 text-muted">
										<input type="checkbox" name="remember" />
										<span></span>Remember me</label>
									</div>
									<a href="javascript:;" id="kt_login_forgot" class="text-muted text-hover-primary">Forget Password ?</a>
								</div> -->
								<!-- <button id="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 col-lg-6 col-md-6 col-sm-10 cols-10">Sign In</button> -->
                <button
                  @click="login()"
                   v-if="!buttonLock"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Sign In
                </button>
                 <b-button v-if="buttonLock" variant="primary" disabled  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3" >
                    <b-spinner small ></b-spinner>
                  Loading...
                </b-button>
                <b-button v-if="disabledBtn" variant="primary" disabled>
                    <b-spinner small ></b-spinner>
                  Loading...
                </b-button>
							<div class="mt-10">
								<!-- <span class="opacity-70 mr-4">Don't have an account yet?</span> -->
								<!-- <a href="javascript:;" id="kt_login_signup" class="text-muted text-hover-primary font-weight-bold">Sign Up!</a> -->
							</div>
						</div>
						<!--end::Login Sign in form-->
					
					</div>
				</div>
			</div>
			<!--end::Login-->
		</div>
		<!--end::Main-->
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import axios from "axios";
// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
// import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import GoogleLogin from 'vue-google-login';
export default {
  name: "login-1",
  data() {
    return {
      visibled: false,
        disabledBtn: false,
       // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
        params: {
            client_id: "753065350883-71sqo61a56gr9gv0b8noc53bc7oe33f1.apps.googleusercontent.com"
        },
        // only needed if you want to render the button with the google ui
        renderParams: {
            width: 250,
            height: 50,
            longtitle: true
        },
   
      state: "signin",
      // Remove this dummy login info
      form: {
        username: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "auth"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "/media/svg/illustrations/working.svg"
      );
    },
     buttonLock(){
       return this.$store.getters[ "common/buttonLock"];
     },
  },



  mounted() {


    // // location.reload();
     
    // const signin_form = KTUtil.getById("kt_login_signin_form");
    // const signup_form = KTUtil.getById("kt_login_signup_form");
    // const forgot_form = KTUtil.getById("kt_login_forgot_form");

    // this.fv = formValidation(signin_form, {
    //   fields: {
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Username is required"
    //         }
    //       }
    //     },
    //     password: {
    //       validators: {
    //         notEmpty: {
    //           message: "Password is required"
    //         }
    //       }
    //     }
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap()
    //   }
    // });

    // this.fv1 = formValidation(signup_form, {
    //   fields: {
    //     fullname: {
    //       validators: {
    //         notEmpty: {
    //           message: "Full name is required"
    //         }
    //       }
    //     },
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Email is required"
    //         },
    //         emailAddress: {
    //           message: "The value is not a valid email address"
    //         }
    //       }
    //     },
    //     password: {
    //       validators: {
    //         notEmpty: {
    //           message: "Password is required"
    //         }
    //       }
    //     },
    //     cpassword: {
    //       validators: {
    //         notEmpty: {
    //           message: "Confirm password is required"
    //         },
    //         identical: {
    //           compare: function() {
    //             return signup_form.querySelector('[name="password"]').value;
    //           },
    //           message: "The password and its confirm are not the same"
    //         }
    //       }
    //     },
    //     agree: {
    //       validators: {
    //         notEmpty: {
    //           message: "You should agree terms and conditions"
    //         }
    //       }
    //     }
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap()
    //   }
    // });

    // this.fv2 = formValidation(forgot_form, {
    //   fields: {
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Email is required"
    //         },
    //         emailAddress: {
    //           message: "The value is not a valid email address"
    //         }
    //       }
    //     }
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap()
    //   }
    // });

    // this.fv.on("core.form.valid", () => {
    //   var email = this.form.email;
    //   var password = this.form.password;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signin_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send login request
    //     this.$store
    //       .dispatch(LOGIN, { email, password })
    //       // go to which page after successfully login
    //       .then(() => this.$router.push({ name: "dashboard" }))
    //       .catch(() => {});

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // });

    // this.fv.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Please, provide correct data!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false
    //   });
    // });

    // this.fv1.on("core.form.valid", () => {
    //   const email = this.$refs.remail.value;
    //   const password = this.$refs.rpassword.value;

    //   // clear existing errors
    //   this.$store.dispatch(LOGOUT);

    //   // set spinner to submit button
    //   const submitButton = this.$refs["kt_login_signup_submit"];
    //   submitButton.classList.add("spinner", "spinner-light", "spinner-right");

    //   // dummy delay
    //   setTimeout(() => {
    //     // send register request
    //     this.$store
    //       .dispatch(REGISTER, {
    //         email: email,
    //         password: password
    //       })
    //       .then(() => this.$router.push({ name: "dashboard" }));

    //     submitButton.classList.remove(
    //       "spinner",
    //       "spinner-light",
    //       "spinner-right"
    //     );
    //   }, 2000);
    // });

    // this.fv1.on("core.form.invalid", () => {
    //   Swal.fire({
    //     title: "",
    //     text: "Please, provide correct data!",
    //     icon: "error",
    //     confirmButtonClass: "btn btn-secondary",
    //     heightAuto: false
    //   });
    // });
  },

  async created(){
    // await ApiService.checkAuthentication();
    // if(this.auth === true){
    //   this.$router.push({ name: "dashboard" });
    // }

  },
  methods: {

    async login(){
      let params = {
        username : this.form.username,
        password : this.form.password,
      }
      await this.$store.dispatch("auth/login",params);
      
    }
  //   activateGoogle(){
  //     this.disabledBtn = !this.disabledBtn;
  //     document.querySelector('.abcRioButtonContentWrapper').click();
  //   },  
  //   async onSuccess(googleUser) {
  //     // console.log(googleUser);
 
  //     // This only gets the user information: id, name, imageUrl and email
  //     // console.log(googleUser.getBasicProfile());
  //     // console.log(googleUser.getAuthResponse());
  //     // console.log(googleUser.getAuthResponse().id_token);


  //     this.disabledBtn = true;


  //     // var tk = localStorage.getItem("token");
  //     // var tkpaser = JSON.parse(tk);
  //     var id_token = googleUser.getAuthResponse().id_token;
  //     var header = {
  //       headers: {
  //         // Authorization: "bearer " + tkpaser,
  //         // "Content-Type": "application/json",
  //         "Content-Type": "text/plain",
  //         "X-Requested-With": "XMLHttpRequest",
  //         "X-Google-OAuth2-Type": "client"
  //       }
  //     };

  //     // const formData = new FormData();
  //     // formData.append("idtoken",id_token);
  //     var data = id_token;


   
  // var res1 = null;
  // var url = this.$store.state.common.baseUrl + "/office-api/gauth/swap_token";
  // try {
  //   res1 = await axios.post(
  //     url, data, header
  //   );

  //   JwtService.saveToken(res1.data.access_token);

    
    
  //   // dummy delay
  //     // setTimeout(() => {
      
  //       // send login request
  //       // console.log("REDIRECT TO DASHBOARD");
  //     // this.$router.push({ name: "dashboard" });

  //     window.location.href = "/dashboard";

  //     // this.disabledBtn = false;


  //     // }, 2000);


  // } catch (error) {
  //   // console.log("ERROR11111 >>>>> ", error);
  //   this.disabledBtn = false;
      
  //   // this.shapeFormat.noticeError(error);
  // }


  //   },
  //   onFailure(googleUser) {
  //      this.disabledBtn = false;
  //     // console.log(googleUser);
 
  //     // This only gets the user information: id, name, imageUrl and email
  //     // console.log(googleUser.getBasicProfile());
  //   },
  //   showForm(form) {
  //     this.state = form;
  //     var form_name = "kt_login_" + form + "_form";
  //     KTUtil.animateClass(
  //       KTUtil.getById(form_name),
  //       "animate__animated animate__backInUp"
  //     );
  //   }
  },
  components: {
      // GoogleLogin
  }
};
</script>


<style >
.abcRioButtonLightBlue {
   
    width: 1px !important;
    height: 1px !important;
}
</style>
